import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Image,
  FloatingHelper,
  ToggleSwitch,
  Loader,
  FloatingNotification,
  EmptyState,
  TextButton,
  Heading,
  Modal,
  MessageModalLayout,
  Text,
  Card,
  Box,
  Page,
  Button,
  Cell,
  Layout,
  WixDesignSystemProvider,
  Badge,
  AnnouncementModalLayout,
  Tooltip,
  PulseAnimation,
  FormField,
  VariableInput,
  SectionHelper,
  MarketingPageLayoutContent,
  PopoverMenu,
  MarketingLayout,
  SegmentedToggle,
  Notification,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import * as Icons from "@wix/wix-ui-icons-common";
import "./App.css";
import { ReactTagManager } from "react-gtm-ts";
import * as Sentry from "@sentry/react";
import Assistant from "./assistant.png";
import { useTranslation } from "react-i18next";
import "./App.css";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

const CONTENT = [
  "Unlimited Conversation",
  "Dedicated onboarding specialist",
  "Optimization sessions with expert",
  "Access to GPT-4 + Vision (Image)*",
  "Longer message lookback",
];

const INTERVAL = 4000;

function App() {
  const { t } = useTranslation()
  ReactTagManager.init(tagManagerArgs);

  /* Wix Owner App - Start */
  const isOwnerApp =
    new URLSearchParams(window.location.search).get("openedIn") === "ownerApp";
  const isPremiumUpgradeDisallowed =
    new URLSearchParams(window.location.search).get(
      "isPremiumUpgradeAllowed"
    ) === "false";
  /* Wix Owner App - End */

  const [showVideo, setShowVideo] = React.useState(false);
  const [embedId, setEmbedId] = React.useState("");
  const [videoName, setVideoName] = React.useState("");
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = React.useState(false);
  const [showFaqPermissionModal, setShowFaqPermissionModal] =
    React.useState(false);
  const [isSaving, setIsSaving] = React.useState(true);
  const [config, setConfig] = React.useState({} as any);
  const [index, setIndex] = useState<number>(0);
  const [showSetupExperience, setShowSetupExperience] = React.useState(true);

  const [variableKeys, setVariableKeys] = React.useState({
    siteProperties: "Site Properties",
    userData: "User Data",
    faqs: "All FAQs",
  } as any);

  const [systemTemplate, setSystemTemplate] = React.useState("");
  const [defaultTemplate, setDefaultTemplate] = React.useState("");

  const [instanceData, setInstanceData] = React.useState(null as any);
  const [isUpgradeBannerOpen, setIsUpgradeBannerOpen] =
 useState<boolean>(false);

  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const locale = instanceData?.site?.locale;

  const isInReview = config?.isInReview;
  const isBanned = config?.isBanned;
  const useGpt4 = config?.useGpt4;
  const useFaq = config?.useFaq;
  const isOpen = config?.isEnabled;

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
    getSettings();
  }, []);

  useEffect(() => {
    const sliderInterval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % CONTENT.length);
    }, INTERVAL);

    return () => clearInterval(sliderInterval);
  }, [CONTENT, INTERVAL]);

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=610f265e-e7cd-4377-9f7e-c2075b263634&redirectUrl=https://certifiedcode.editorx.io/chat/_functions/@certifiedcode/base-backend/auth`;
  }

  const bodyRef = useRef();

  const variables = useRef(
    Object.keys(variableKeys).map((key) => ({
      value: key,
      key: variableKeys[key],
    }))
  ).current;

  useEffect(() => {
    if (isSaving === false) {
      setSettings();
    } else {
      setIsSaving(false);
    }
    setSystemTemplate(config?.template || config?.default_template);
  }, [config]);

  const insertVariable = useCallback((variable: any) => {
    if (bodyRef.current) {
      //@ts-ignore
      bodyRef.current.insertVariable(variable.key);
    }
  }, []);

  if (!!token) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text={t('loading')} />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  const isAdvanced = ["advanced"].includes(
    instanceData?.instance?.billing?.packageName
  );

  const isUnlimited = ["unlimited"].includes(
    instanceData?.instance?.billing?.packageName
  );

  const canUseEnterpriseFeatures = ["elite", "enterprise"].includes(
    instanceData?.instance?.billing?.packageName
  );

  const canUseEliteFeatures = ["elite"].includes(
    instanceData?.instance?.billing?.packageName
  );

  const canUseAdvancedFeatures = ["advanced", "elite", "enterprise"].includes(
    instanceData?.instance?.billing?.packageName
  );

  const canUseUnlimitedFeatures = [
    "unlimited",
    "advanced",
    "elite",
    "enterprise",
  ].includes(instanceData?.instance?.billing?.packageName);

  const BASE_URL = `https://certifiedcode.editorx.io/chat/_functions`;

  const YoutubeEmbed = ({ embedId }: any) => (
    <iframe
      width="560px"
      height="315px"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title={t('youtube-title')}
    />
  );

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.email) {
          Sentry.setUser({ email: data.email });
          window._cio.identify({
            id: data.email,
            email: data.email,
          });
        }
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        Sentry.setUser({ id: data.instance.id });
        setInstanceData(data);
        const isFree = data.instance.isFree === false;
        setIsUpgraded(isFree);
        setIsUpgradeBannerOpen(!isFree);
      })
      .catch(() => {
        setIsModalOpen(true);
      });
  }

  function setSettings() {
    setIsSaving(true);
    fetch(BASE_URL + "/settings", {
      method: "POST",
      headers: {
        Authorization: instance || "",
      },
      body: JSON.stringify(config),
    })
      .then(() => {
        if (bodyRef.current) {
          // @ts-ignore
          bodyRef.current.setValue(
            config?.template || config?.default_template
          );
        }
        setIsSaving(false);
        // getSettings();
      })
      .catch(() => {});
  }

  function getSettings() {
    setIsSaving(true);
    fetch(BASE_URL + "/settings", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setConfig(data);
        setSystemTemplate(data.template || data.default_template);
        setDefaultTemplate(data.default_template);
        setIsSaving(false);
      })
      .catch(() => {});
  }

  class ChatAppMissing extends React.Component {
    render() {
      return (
        <AnnouncementModalLayout
          title={t('install-chat-title')}
          primaryButtonText={t('install-now-chat-button')}
          primaryButtonOnClick={() => {
            window.open(
              `https://manage.wix.com/dashboard/${instanceData?.site?.siteId}/app-market/web-solution/wix-chat`
            );
          }}
        >
          <Text>
          {t('install-chat-content')}
          </Text>
        </AnnouncementModalLayout>
      );
    }
  }

  const getUpgradeHint = () => {
    if (instanceData?.instance?.billing?.billingCycle === "YEARLY") {
      return t('yearly-sub-msg');
    }
    if (instanceData?.instance?.billing?.billingCycle === "MONTHLY") {
      return t('monthly-sub-msg');
    }
    return t('unlimited-sub-msg');
  };

  const getPriority = () => {
    if (instanceData?.instance?.billing?.billingCycle === "YEARLY") {
      return "secondary";
    }
    if (instanceData?.instance?.billing?.billingCycle === "MONTHLY") {
      return "primary";
    }
    return "primary";
  };

  const getUpgradeText = () => {
    if (instanceData?.instance?.billing?.billingCycle === "YEARLY") {
      return t('yearly-upgrade-msg');
    }
    if (instanceData?.instance?.billing?.billingCycle === "MONTHLY") {
      return t('monthly-upgrade-msg');
    }
    return t('unlimited-upgrade-msg');
  };

  var isWixChatMissing = !(
    instanceData?.site?.installedWixApps || ["chat"]
  ).includes("chat");

  const handleShowFAQVideo = () => {
    setShowVideo(true);
    setEmbedId("1eTqzPVXIqY");
    setVideoName(t('video-name'));
  };
  const handleFAQToggle = () => {
    // find instanceData.instance.permissions includes "FAQ.READ_QUESTIONS". If not, show modal to ask user to grant permission.
    const isGranted = (instanceData as any)?.instance?.permissions?.includes(
      "FAQ.READ_QUESTIONS"
    );
    if (!isGranted) {
      setShowFaqPermissionModal(true);
    } else {
      setConfig({ ...config, useFaq: !useFaq });
    }
  };
  const handleGPT4Toggle = () => {
    setConfig({ ...config, useGpt4: !useGpt4 });
  };
  // if (showSetupExperience) {
  //   return <WixDesignSystemProvider features={{ newColorsBranding: true }}>
  //     <Page height='100vh'>
  //       <Page.Header title='Setup Chat: GPT-based conversations'
  //       actionsBar={
  //         <Button skin="inverted" onClick={()=>{
  //           setShowSetupExperience(false);
  //         }}>Skip Setup</Button>
  //       }
  //       ></Page.Header>
  //       <Page.Content>
  //         <Layout>
  //           <Cell><Stepper
  //             fit="compact"
  //             activeStep={0}
  //             steps={[{ text: 'Choose Integration Solution' }, { text: 'Review Settings' }, { text: 'Try it out!' }]}
  //           /></Cell>
  //           <Cell><Card>
  //             <Card.Content>
  //               <Box direction="vertical" gap="SP2">
  //                 <SectionHelper appearance="preview">Choose one to setup first. You can add/remove later.</SectionHelper>
  //                 <Box direction="horizontal" gap="SP2">
  //                   <Thumbnail
  //                     title="Live Chat"
  //                     description="A chat widget (Wix Chat) that pinned to the bottom of your site"
  //                     image={<Image width="200px" src={LiveChat} />}
  //                     height={300}
  //                     contentAlignment="center"
  //                     width={400}
  //                   />
  //                   <Thumbnail
  //                     title="Q&A Widget"
  //                     description="A widget placed inline with your site content, with customizable persona on each widget"
  //                     image={<Image width="200px" src={ChatWidget} />}
  //                     height={300}
  //                     contentAlignment="center"
  //                     width={400}
  //                   />
  //                 </Box>
  //               </Box>
  //             </Card.Content>
  //           </Card></Cell>
  //           <Cell><Card>
  //             <Card.Content>
  //               <Box gap="SP3">
  //                 <Avatar color="A2" name={'marcelmeier'} />
  //                 <Box direction="vertical" gap="6px">
  //                   <Box>
  //                     <Text size="small">marcelmeier </Text>
  //                     <Text size="small" secondary>
  //                       / Jun 28, 2023
  //                     </Text>
  //                   </Box>
  //                   <StarsRatingBar value={5} readOnly size="small" />
  //                   <Text weight="bold">Amazingly smart and friendly</Text>
  //                   <Text weight="thin">
  //                     We have completed the WIX FAQ app and activated ChatGPT in WIXChat. After a few initial stumbles, ChatGPT now understands a lot of customer questions and prepares the information so well that our customers receive the answers they are looking for quickly, friendly and competently. We are amazed and also pleased that ChatGPT is constantly learning and is very accommodating and interacts with visitors.
  //                   </Text>
  //                 </Box>
  //               </Box>
  //             </Card.Content>
  //           </Card></Cell>
  //         </Layout>
  //       </Page.Content>
  //     </Page>
  //   </WixDesignSystemProvider>
  // }

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      {!instanceData && (
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text={t('loading')} />
            </Box>
          </Page.Content>
        </Page>
      )}
      <Notification theme="premium" show={isUpgradeBannerOpen} type={"sticky"}>
        <Notification.TextLabel>  {t('upgradeMessage', { content: t(`content.${index}`) })} </Notification.TextLabel>
        <Notification.ActionButton
          onClick={() => {
            window.open(
              `https://www.wix.com/apps/upgrade/${"610f265e-e7cd-4377-9f7e-c2075b263634"}?appInstanceId=${
                instanceData["instance"]["instanceId"]
              }`
            );
          }}
        >
         {t('upgrade-button')}
        </Notification.ActionButton>
        <Notification.CloseButton
          className="close-button"
          onClick={() => setIsUpgradeBannerOpen(false)}
        />
      </Notification>
      <Page
        height="100vh"
        minWidth={0}
        className={`${isUpgradeBannerOpen ? "App-header-notification" : ""}`}
      >
        <Page.Header
          title={
            <Box gap={"SP2"} alignItems="middle">
              <Heading>{t('heading-chat')}</Heading>
              <Badge
                skin={canUseUnlimitedFeatures ? "general" : "danger"}
                size="small"
              >
                {instanceData?.instance?.billing?.packageName}
              </Badge>
            </Box>
          }
          actionsBar={
            <Box direction="horizontal" gap="SP1">
              {isUpgraded && (
                <Button
                  prefixIcon={<Icons.Reviews />}
                  skin="inverted"
                  as="a"
                  href="https://bridget.reviews.certifiedcode.us/610f265e-e7cd-4377-9f7e-c2075b263634"
                  target="_blank"
                >
                  {t('write-review-button')}
                </Button>
              )}
              <Box direction="vertical">
                <Button
                  skin="premium"
                  priority={getPriority()}
                  prefixIcon={<Icons.PremiumFilled />}
                  onClick={() => setIsUpgradeModalOpen(true)}
                >
                  {getUpgradeText()}
                </Button>
                <Badge size="tiny" skin="neutralLight">
                  {getUpgradeHint()}
                </Badge>
              </Box>
            </Box>
          }
        />
        <Page.Content>
          <Modal isOpen={showVideo}>
            <AnnouncementModalLayout
              title={videoName}
              onCloseButtonClick={() => {
                setShowVideo(false);
              }}
            >
              <YoutubeEmbed embedId={embedId} />
            </AnnouncementModalLayout>
          </Modal>
          <Modal isOpen={isWixChatMissing} shouldCloseOnOverlayClick={false}>
            {<ChatAppMissing></ChatAppMissing>}
          </Modal>
          <Modal
            isOpen={showFaqPermissionModal}
            shouldCloseOnOverlayClick={true}
          >
            <AnnouncementModalLayout
              title={t('announcement-title')}
              primaryButtonText={t('announcement-primary-button')}
              primaryButtonOnClick={() => {
                window.open(
                  `https://www.wix.com/installer/install?appId=610f265e-e7cd-4377-9f7e-c2075b263634&metaSiteId=${
                    instanceData?.site ? instanceData?.site?.siteId : ""
                  }&redirectUrl=https://certifiedcode.editorx.io/chat/_functions/@certifiedcode/base-backend/auth`
                );
              }}
              linkText={t('announcement-link-button')}
              linkOnClick={() => {
                setConfig({ ...config, useFaq: !config.useFaq });
                setShowFaqPermissionModal(false);
              }}
            >
              <Text>
               {t('permission-text')}{" "}
                <b>{t('permission-bold-text')}</b>
              </Text>
            </AnnouncementModalLayout>
          </Modal>
          <Modal
            isOpen={isUpgradeModalOpen}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => setIsUpgradeModalOpen(false)}
          >
            <AnnouncementModalLayout
              theme="premium"
              title={t('ai-conversation-title')}
              primaryButtonText={
                !isPremiumUpgradeDisallowed
                  ? t('ai-conversation-upgrade-button')
                  : t('ai-conversation-upgrading-button')
              }
              linkText={t('ai-conversation-link-button')}
              primaryButtonOnClick={() => {
                if (!isPremiumUpgradeDisallowed) {
                  window.open(
                    `https://www.wix.com/apps/upgrade/${"610f265e-e7cd-4377-9f7e-c2075b263634"}?appInstanceId=${
                      instanceData["instance"]["instanceId"]
                    }`
                  );
                }
              }}
              linkOnClick={() => {
                window.open(
                  `https://support.certifiedcode.us/en/articles/7851398-how-chat-gpt-based-conversation-s-plans-work`
                );
              }}
              onCloseButtonClick={() => setIsUpgradeModalOpen(false)}
            >
              <Text>
                Upgrade "Chat - GPT based conversations" to start having
                conversations with site visitors and members with AI. All at one
                price.
              </Text>
            </AnnouncementModalLayout>
          </Modal>
          <Modal isOpen={isModalOpen} screen="desktop">
            <MessageModalLayout
              primaryButtonText={t('something-refresh-button')}
              primaryButtonOnClick={() => window.location.reload()}
              secondaryButtonText={t('something-contact-support-button')}
              secondaryButtonOnClick={() => {
                window.Intercom(
                  "showNewMessages",
                  t('help-message')
                );
              }}
              title={t('something-wrong-title')}
              content={
                <Text>
                 {t('something-wrong-text')}
                </Text>
              }
            />
          </Modal>
          <Layout>
            {isUnlimited && (
              <Cell span={12}>
                <Card>
                  <MarketingLayout
                    size="tiny"
                    title={t('chat-upgrade-title')}
                    description={t('chat-upgrade-description')}
                    actions={
                      <Box gap="SP1">
                        <Button
                          size="small"
                          skin="dark"
                          suffixIcon={<Icons.ExternalLinkSmall />}
                          as="a"
                          href={`https://www.wix.com/apps/upgrade/${"610f265e-e7cd-4377-9f7e-c2075b263634"}?appInstanceId=${
                            instanceData["instance"]["instanceId"]
                          }`}
                          target="_blank"
                        >
                          {t('chat-upgrade-plan-button')}
                        </Button>
                      </Box>
                    }
                    image={
                      <Image
                        width="100px"
                        src={Assistant}
                        transparent
                        borderRadius={"999px"}
                      />
                    }
                  />
                </Card>
              </Cell>
            )}
            {isAdvanced && (
              <Cell span={12}>
                <Card>
                  <MarketingLayout
                    size="tiny"
                    title={t('upgraded-title')}
                    description={t('upgraded-description')}
                    actions={
                      <Box gap="SP1">
                        <Button
                          size="small"
                          skin="dark"
                          suffixIcon={<Icons.ExternalLinkSmall />}
                          as="a"
                          href={`https://www.wix.com/apps/upgrade/${"610f265e-e7cd-4377-9f7e-c2075b263634"}?appInstanceId=${
                            instanceData["instance"]["instanceId"]
                          }`}
                          target="_blank"
                        >
                          {t('upgraded-plan-button')}
                        </Button>
                      </Box>
                    }
                    image={
                      <Image
                        width="100px"
                        src={Assistant}
                        transparent
                        borderRadius={"999px"}
                      />
                    }
                  />
                </Card>
              </Cell>
            )}
            <Cell span={12}>
              {locale && locale !== "en" && (
                <SectionHelper
                  fullWidth
                  appearance="preview"
                  title={t('section-title')}
                >
                 {t('section-description')}
                </SectionHelper>
              )}
            </Cell>
            <Cell span={8}>
              <Card>
                <Card.Header
                  title={
                    <Box gap={"SP2"}>
                      <Heading size="medium" as="h2">
                        {t('selection-heading')}
                      </Heading>
                      {isUpgraded ? (
                        <Badge size="tiny" skin={"success"}>
                          {t('selection-badge')}
                        </Badge>
                      ) : (
                        <FloatingHelper
                          // width={200}
                          target={
                            <Badge size="tiny" skin={"danger"}>
                              {t('floating-badge')}
                            </Badge>
                          }
                          content={
                            <FloatingHelper.Content
                              title={t('floating-title')}
                              body={t('floating-body')}
                              actionText={t('floating-action')}
                              actionTheme="lightPrimary"
                              appearance="dark"
                              onActionClick={() => {
                                window.open(
                                  `https://support.certifiedcode.us/en/articles/7849238-getting-started-with-chat-gpt-based-conversation#h_0c30ca2598`
                                );
                              }}
                            />
                          }
                          placement="right"
                        />
                      )}
                    </Box>
                  }
                  subtitle={t('floating-subtitle')}
                  suffix={
                    <PulseAnimation
                      active={config?.isFirstTime}
                      color="B10"
                      borderRadius={"6px"}
                    >
                      <SegmentedToggle
                        size="small"
                        selected={
                          config?.isEnabled === true
                            ? config?.mode || "standard"
                            : "disabled"
                        }
                        // onClick={(_, value) => {
                        //   console.log(value);
                        //   if (value === "disabled") {
                        //     setConfig({
                        //       ...config,
                        //       isEnabled: false,
                        //       mode: null,
                        //     });
                        //   } else {
                        //     setConfig({
                        //       ...config,
                        //       isEnabled: true,
                        //       mode: value,
                        //     });
                        //   }
                        // }}
                      >
                        <SegmentedToggle.Button
                          prefixIcon={<Icons.ChatDisabled />}
                          value="disabled"
                        >
                          Off
                        </SegmentedToggle.Button>
                        <SegmentedToggle.Button
                          // disabled
                          prefixIcon={<Icons.Chat />}
                          value="chatgpt"
                        >
                          <Tooltip content={t('tool-1-content')}>
                          {t('tool-1-title')}
                          </Tooltip>
                        </SegmentedToggle.Button>
                        <SegmentedToggle.Button
                          prefixIcon={<Icons.AIFilled />}
                          value="standard"
                        >
                          <Tooltip content={t('tool-2-content')}>
                            {t('tool-2-title')}
                          </Tooltip>
                        </SegmentedToggle.Button>
                      </SegmentedToggle>
                    </PulseAnimation>
                  }
                />
                {isOpen && (
                  <>
                    {isInReview ? (
                      <FloatingNotification
                        type="warning"
                        fullWidth
                        prefixIcon={<Icons.StatusWarning />}
                        text={t('floating-notification-review-text')}
                        showCloseButton={false}
                      />
                    ) : isBanned ? (
                      <FloatingNotification
                        type="destructive"
                        fullWidth
                        prefixIcon={<Icons.Block />}
                        text={t('floating-notification-banned-text')}
                        showCloseButton={false}
                      />
                    ) : (
                      <Card.Divider />
                    )}
                    <Card.Content>
                      <EmptyState
                        theme="section"
                        title={t('chat-live-title')}
                        subtitle={t('chat-live-subtitle')}
                      >
                        <TextButton
                          prefixIcon={<Icons.ExternalLink />}
                          as={"a"}
                          href={
                            instanceData?.site ? instanceData?.site.url : ""
                          }
                          target={"_blank"}
                        >
                          {t('chat-live-button')}
                        </TextButton>
                      </EmptyState>
                    </Card.Content>{" "}
                  </>
                )}
                <Card.Divider />
                {config?.isEnabled && (
                  <Card.Content>
                    <Box verticalAlign="middle" align="space-between">
                      <Box direction="vertical">
                        <Box gap={"SP1"}>
                          <Text weight="bold">{t('gpt-4-title')}</Text>
                          {!canUseAdvancedFeatures && (
                            <Tooltip content={t('gpt-4-content')}>
                              <Badge size="tiny" skin="premium">
                                {t('gpt-4-badge')}
                              </Badge>
                            </Tooltip>
                          )}
                        </Box>
                        <Text secondary size="small">
                          {t('gpt-4-text')}
                        </Text>
                      </Box>
                      {canUseAdvancedFeatures ? (
                        <ToggleSwitch
                          skin="standard"
                          checked={useGpt4}
                          disabled={!(isSaving === false && isOpen === true)}
                          onChange={handleGPT4Toggle}
                          size="medium"
                        />
                      ) : (
                        <PulseAnimation
                          active={true}
                          color="P10"
                          borderRadius="18px"
                        >
                          <Button
                            skin="premium"
                            size="tiny"
                            prefixIcon={<Icons.PremiumFilled />}
                            onClick={() => setIsUpgradeModalOpen(true)}
                          >
                            {t('gtp-4-upgrade-button')}
                          </Button>
                        </PulseAnimation>
                      )}
                    </Box>
                  </Card.Content>
                )}
                {config?.isEnabled && (
                  <Card.Content>
                    <Box verticalAlign="middle" align="space-between">
                      <Box direction="vertical">
                        <Box gap={"SP2"}>
                          <Text weight="normal">{t('gtp-4-vision')}</Text>
                          {!canUseEnterpriseFeatures && (
                            <Badge size="tiny" skin={"premium"}>
                              {t('gtp-4-enterprize-badge')}
                            </Badge>
                          )}
                        </Box>
                        <Text secondary size="small">
                          {t('gtp-4-enabled-text')}
                        </Text>
                      </Box>
                      {canUseAdvancedFeatures ? (
                        <Tooltip content={t('gtp-4-advanced-content')}>
                          <ToggleSwitch
                            disabled={!canUseEnterpriseFeatures}
                            checked={canUseEnterpriseFeatures}
                            size="medium"
                          />
                        </Tooltip>
                      ) : (
                        <PulseAnimation
                          active={true}
                          color="P10"
                          borderRadius="18px"
                        >
                          <Button
                            skin="premium"
                            size="tiny"
                            prefixIcon={<Icons.PremiumFilled />}
                            onClick={() => setIsUpgradeModalOpen(true)}
                          >
                            {t('gtp-4-upgrade-button')}
                          </Button>
                        </PulseAnimation>
                      )}
                    </Box>
                  </Card.Content>
                )}
                {config?.isEnabled && config?.mode === "standard" && (
                  <Card.Content>
                    <Box verticalAlign="middle" align="space-between">
                      <Box direction="vertical">
                        <Box gap={"SP2"}>
                          <Text weight="normal">
                            {t('share-site')}
                          </Text>
                        </Box>
                        <Text secondary size="small">
                          {t('wix-text')}{" "}
                          <Box inline>
                            <TextButton
                              size="small"
                              underline="always"
                              suffixIcon={<Icons.ExternalLinkSmall />}
                              as="a"
                              href="https://support.wix.com/en/article/adding-your-sites-business-information"
                              target="_blank"
                            >
                              {t('wix-button')}
                            </TextButton>
                          </Box>
                        </Text>
                      </Box>
                      <Tooltip content={t('wix-tooltip')}>
                        <ToggleSwitch checked size="medium" />
                      </Tooltip>
                    </Box>
                  </Card.Content>
                )}
                {config?.isEnabled && config?.mode === "standard" && (
                  <Card.Content>
                    <Box verticalAlign="middle" align="space-between">
                      <Box direction="vertical">
                        <Box gap={"SP2"}>
                          <Text weight="normal">
                            {t('wix-share-text')}
                          </Text>
                        </Box>
                        <Text secondary size="small">
                         {t('wix-ai-text')}{" "}
                          <Box inline>
                            <TextButton
                              size="small"
                              underline="always"
                              suffixIcon={<Icons.ExternalLinkSmall />}
                              as="a"
                              href="https://support.wix.com/en/article/site-members-adding-and-setting-up-a-members-area"
                              target="_blank"
                            >
                              {t('wix-setup-button')}
                            </TextButton>
                          </Box>
                        </Text>
                      </Box>
                      <Tooltip content={t('wix-tooltip')}>
                        <ToggleSwitch checked size="medium" />
                      </Tooltip>
                    </Box>
                  </Card.Content>
                )}
                {config?.isEnabled && config?.mode === "standard" && (
                  <Card.Content>
                    <Box verticalAlign="middle" align="space-between">
                      <Box direction="vertical">
                        <Box gap={"SP2"}>
                          <Text weight="normal">
                            {t('wix-faq-text')}
                          </Text>
                          <Badge size="tiny" skin="neutralSuccess">
                          {t('wix-faq-badge')}
                          </Badge>
                        </Box>
                        <Text secondary size="small">
                        {t('wix-faq-content')}{" "}
                          <Box inline>
                            <TextButton
                              size="small"
                              underline="always"
                              suffixIcon={<Icons.ExternalLinkSmall />}
                              as="a"
                              href="https://support.wix.com/en/wix-faq-app"
                              target="_blank"
                            >
                              {t('wix-faq-button')}
                            </TextButton>
                          </Box>
                        </Text>
                      </Box>
                      <ToggleSwitch
                        skin="standard"
                        disabled={!(isSaving === false && isOpen === true)}
                        checked={useFaq}
                        onChange={handleFAQToggle}
                        size="medium"
                      />
                    </Box>
                  </Card.Content>
                )}
              </Card>
            </Cell>
            <Cell span={4}>
              <Box gap="SP4" direction="vertical">
                <Card>
                  <Card.Content>
                    <MarketingPageLayoutContent
                      size="medium"
                      title={t('learn-chat-title')}
                      content={t('learn-chat-content')}
                      actions={
                        <Button
                          size="large"
                          onClick={() => {
                            setShowVideo(true);
                            setEmbedId("l-v3qqGjIMA");
                            setVideoName(t('learn-chat-video-name'));
                          }}
                        >
                          {t('learn-chat-video-button')}
                        </Button>
                      }
                    />
                  </Card.Content>
                </Card>
                {config?.mode !== "chatgpt" && (
                  <Card>
                    <Card.Content>
                      <MarketingPageLayoutContent
                        size="medium"
                        title={t('improve-chat-title')}
                        content={t('improve-chat-content')}
                        actions={
                          <Button size="large" onClick={handleShowFAQVideo}>
                            {t('improve-chat-learn-button')}
                          </Button>
                        }
                      />
                      
                    </Card.Content>
                  </Card>
                )}
              </Box>
            </Cell>
            {config?.isEnabled && config?.mode === "standard" && (
              <Cell span={12}>
                <Card>
                  <Card.Header
                    title={
                      <Box gap="SP1">
                        <Text size="medium" weight="bold">
                          {t('advanced-chat-title')}
                        </Text>
                        <Badge size="tiny" skin="warningLight">
                         {t('advanced-chat-badge')}
                        </Badge>
                      </Box>
                    }
                    subtitle={t('advanced-chat-subtitle')}
                  ></Card.Header>
                  <Card.Divider></Card.Divider>
                  <Card.Content>
                    <Layout>
                      <Cell>
                        <FormField label={t('system-message')} required>
                          <Box gap="10px">
                            <VariableInput
                              // variableParser={(value) => {
                              //   const variable = variables.find(
                              //     (item) => item.key === value
                              //   );

                              //   return variable ? variable.value : false;
                              // }}
                              // // @ts-ignore
                              // ref={bodyRef}
                              // rows={10}
                              // value={systemTemplate}
                              // onChange={(e) => setSystemTemplate(e)}
                            />
                            <Box gap="SP1" direction="vertical">
                              <Button
                                prefixIcon={<Icons.Confirm />}
                                size="small"
                                disabled={isSaving}
                                onClick={() => {
                                  setConfig({
                                    ...config,
                                    template: systemTemplate,
                                  });
                                }}
                              >
                                {isSaving ? t('save-button') : t('saved-button')}
                              </Button>
                              <Button
                                prefixIcon={<Icons.RevertReset />}
                                skin="light"
                                size="small"
                                onClick={() => {
                                  if (bodyRef.current) {
                                    // @ts-ignore
                                    bodyRef.current.setValue(defaultTemplate);
                                  }
                                }}
                              >
                                {t('reset-button')}
                              </Button>
                              <PopoverMenu
                                triggerElement={
                                  <Button
                                    prefixIcon={<Icons.Add />}
                                    skin="light"
                                    size="small"
                                  >
                                    {t('insert-button')}
                                  </Button>
                                }
                              >
                                {variables.map((variable) => (
                                  <PopoverMenu.MenuItem
                                    text={variable.key}
                                    onClick={() => insertVariable(variable)}
                                  />
                                ))}
                              </PopoverMenu>
                            </Box>
                          </Box>
                        </FormField>
                      </Cell>
                    </Layout>
                  </Card.Content>
                </Card>
              </Cell>
            )}
          </Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
